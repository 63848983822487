import { Box, Divider, Heading, HStack, Icon, Stack, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import Container from '../components/container'
import CTASection from '../components/cta'
import ImageSlider from '../components/image-slider'
import Layout from '../components/layout'
import PerkList from '../components/perk-list'
import SEO from '../components/seo'
import StampList from '../components/stamp-list'

export const query = graphql`
	query($slug: String!) {
		contentfulPage(slug: {eq: $slug}) {
			title
			metadescription {
				metadescription
			}
			...servicePageData
		}
	}
`

const ContentfulTemplateService = ({data, ...rest}) => {

	const service = data.contentfulPage.template

	return(

		<Layout>
			<SEO 
				title={data.contentfulPage.title}
				description={data.contentfulPage.metadescription.metadescription}
			/>
			<CTASection mt={{base: "133px", md: "153.11px" }} data={service.hero} />
			<Box backgroundColor="brand.700">
				<Container>
					<Stack direction={{base: "column", md: "row"}} alignItems="center" justifyContent="space-between">
						<HStack>
							<Icon as={FaMapMarkerAlt} color="white" boxSize={{base: 6, md: 8}} />
							<Heading fontSize={{base: "xl", md: "2xl"}} color="white">Onde a Climatec atende</Heading>
						</HStack>
						<Wrap direction={{base: "column", md: "row"}}  spacing={4} >
							{service.operatingAreas.map((item, index) => (
								<WrapItem justifyContent="center" key={`operating-area-${index+1}`} >
									<Heading textAlign="center" as="h4" fontSize={{base: "md", md: "lg"}} color="white">{item}</Heading>
									{index < service.operatingAreas.length -1 && <Divider orientation="vertical" ml={4} />}
								</WrapItem>
							))}
						</Wrap>
					</Stack>
				</Container>
			</Box>
			<Container>
					<StampList py={8} stamps={service.stamps} />
			</Container>
			<ImageSlider images={service.images} />
			<PerkList py={32} data={{heading: service.perksHeading, perks: service.perks}} />
		</Layout>

	)

}

export default ContentfulTemplateService