import React from 'react'
import { motion } from "framer-motion"
import { Box, forwardRef } from "@chakra-ui/react"
import { BrandIcon } from "./brand"

const MotionBox = motion.custom(forwardRef((props, ref) => <Box {...ref}  {...props} />))

export default (props) => (
    <MotionBox
			position="absolute"
			color="brand.50"
			zIndex="hide"
			as={BrandIcon}
			{...props}
    />
  )