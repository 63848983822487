import React from 'react'
import { Box, Center, Heading, Square, Stack, Tooltip } from '@chakra-ui/react'
import Img from 'gatsby-image'

const StampList = ({stamps, ...rest}) => (

	<Box {...rest}>
		<Stack direction={{base: "column", md: "row"}} spacing={4}>
			{stamps.map((item, index) => <StampItem {...item} key={index} />)}
		</Stack>
	</Box>

)

const StampItem = ({label, icon, description, ...rest}) => (

	<Tooltip 
		hasArrow 
		openDelay={200} 
		label={description.description} 
		textAlign="center" 
		fontSize="md" 
		backgroundColor="octopus.700"
	>
		<Square 
			{...rest}
			flex="1" 
			cursor="help" 
			backgroundColor="white" 
			p={8} 
			borderRadius="lg"
			boxShadow="xs" 
			color="ocean.700"
			_hover={{backgroundColor: "ocean.50"}}
			transition="all .2s ease"
		>
			<Center flexDirection="column">
				<Img fixed={icon.fixed} />
				<Heading as="h4" pt={2} fontSize="md" >
					{label}
				</Heading>
			</Center>
		</Square>
	</Tooltip>

)

export default StampList