import React from 'react'
import { 
	Box, 
	Heading, 
	HStack, 
	Button, 
	Icon, 
	Text,
	Stack, 
	useTheme, 
	Link,
	Divider, 
	Wrap, 
	WrapItem,
	useBreakpointValue
} from '@chakra-ui/react'
import { FaArrowRight, FaWhatsapp } from 'react-icons/fa'
import Container from './container'
import AnimatedIcon from './animated-icon'
import { graphql, useStaticQuery } from 'gatsby'
import { formatWhatsappNumber } from '../utils'

const CTASection = ({children, hero, data: { 
	heading, 
	description, 
	backgroundImage,
	buttonLink, 
	buttonText 
}, ...rest}) => {

	const { contentfulSitemetadata: {
		paymentMethods, whatsapp
	} } = useStaticQuery(graphql`
		query {
			contentfulSitemetadata {
				paymentMethods
				whatsapp
			}
		}
	`)

	const brandColor = useTheme().colors.brand["500"]
	const bgImageUrl =  backgroundImage && `url(${backgroundImage.fluid.src})` 

	return(
		<Box 
			display="flex"
			alignItems="center"
			backgroundColor={hero ? "white" : "brand.500"}
			height={hero ? "100vh" : "auto"}
			background={{base: null, md: backgroundImage ? `
			linear-gradient(100deg, ${hero ? "white" : brandColor} 50%, transparent 70%),
			${bgImageUrl} no-repeat right` : null}}
			backgroundSize={{base: "cover", md: "contain"}}
			overflow="hidden"
			zIndex="base"
			py={{base: 16, md: 32}}
			{...rest}
		>
			<Container>
				<Stack alignItems="center" justifyContent="start" flexDirection="row" >
					<Box 
						maxWidth={{base: '85%', md: hero ? '45%' : '50%'}} 
						position="relative" 
						zIndex="docked" 
					>
						<Heading 
							as="h1"
							color={hero ? "ocean.700": "white"}
							fontSize={{base: "3xl", md: "5xl"}}
							fontWeight="extrabold"
							pb={4}
						>
							{heading}
						</Heading>
						<Text 
							color={hero ? "octopus.500" : "brand.50"}
							fontSize={{base: "xl", md:"2xl"}}
							textShadow={{base: !hero && "1px 1px 2px rgba(0,0,0,.2)", md: "none"}}
							pb={4}
						>
							{description.description}
						</Text>
						<CTABtn 
							colorScheme={hero ? "brand" : "gray"}
							color={hero ? "ocean.700" : "white"}
							buttonLink={buttonLink} 
							buttonText={buttonText}
							whatsapp={whatsapp}
							hero={hero}
						/>
						<Wrap mt={2} display={{base: "none", md: hero ? "none" : "block"}} spacing={2} >
							{paymentMethods.map((item, index) => (
								<WrapItem justifyContent="center" key={`payment-method-${index+1}`} >
									<Text textAlign="center" fontSize="sm" color="white">{item}</Text>
									{index < paymentMethods.length -1 && <Divider orientation="vertical" ml={2} />}
								</WrapItem>
							))}
						</Wrap>
						<AnimatedIcon top="50%" opacity={hero ? "0.5" : "0.1"} left="50%" transform="translate(-50%, -50%)" boxSize={{base: "36rem", md: "82rem"}}/>
					</Box>
					<Box flex="1" zIndex="docked">
						{children}
					</Box>
				</Stack>
			</Container>
		</Box>

	)
}

export const CTABtn = ({hero, buttonLink, buttonText, colorScheme, color, backgroundColor, whatsapp,  ...rest}) => {

	return(

		<Stack spacing={4} direction={{base: "column", md: "row"}} alignItems={{base: "start", md: "center"}} >
			<Link isExternal href={buttonLink} _hover={{textDecoration: "none"}} >
				{hero &&
					<Button 
						borderRadius="sm" 
						leftIcon={<FaWhatsapp />} 
						colorScheme="whatsapp" 
						backgroundColor="#25D366" 
						color="white"
						fontWeight="bold"
						_hover={{textDecoration: "none", backgroundColor: "whatsapp.600"}}
						textTransform="uppercase"
					>
						{buttonText}
					</Button>
				}
				{!hero &&
					<Button
						borderRadius="sm"
						colorScheme={colorScheme || "brand"}
						fontWeight="extrabold"
						fontSize={{base: "xs", md: "md"}}
						textTransform="uppercase"
						rightIcon={<FaArrowRight />}
						{...rest}
					>
						{buttonText}
					</Button>
				}
				
			</Link>
			<Link isExternal href={`https://wa.me/55${formatWhatsappNumber(whatsapp)}`}>
				<HStack color={color || "ocean.700"} fontSize="xl" fontWeight="bold">
					<Icon as={FaWhatsapp} />
					<Text>{whatsapp}</Text>
				</HStack>
			</Link>
		</Stack>
	)

}

export default CTASection