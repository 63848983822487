import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import Img from 'gatsby-image'
import Container from './container'


const PerkList = ({data, ...rest}) => {

	const { heading, perks } = data

	return(
		<Box {...rest} py={{base: 16, md: 32}}>
			<Container>
				<Heading
					as="h2" 
					fontSize={{base: "3xl", md: "5xl"}}
					fontWeight="extrabold"
					textAlign="center"
					pb={{base: 8, md: 16}}
				>
					{heading}
				</Heading>
			</Container>
			<Container>
				<SimpleGrid columns={{base: 2, md: 3}} spacing={{base: 8, md: 32}}>
					{perks.map((item, index) => <PerkItem {...item} key={index} />)}
				</SimpleGrid>
			</Container>
		</Box>
	)
}

const PerkItem = ({description, icon, ...rest}) => (
	<Box {...rest} textAlign="center">
		<Img fixed={icon.fixed} />
		<Heading 
			as="h3" 
			color="octopus.500" 
			fontSize={{base: "lg", md: "2xl"}}
			fontWeight="normal"
			lineHeight="base"
			pt={{base: 2, md: 4}}
		>
			{description}
		</Heading>
	</Box>
)

export default PerkList